import { useEffect, useState } from "react";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
const SortingArrow = ({ onSort, name, crrSort = "id", sortDrr = "desc" }) => {
 
  const [isSortedAsc, setIsSortedAsc] = useState(true);
  const [sortName, setSortName] = useState("");
  const [sortDir, setSortDir] = useState("");
  const handleSort = (fieldName) => {
    const newSortDirection = !isSortedAsc;
    setIsSortedAsc(newSortDirection);
   
    onSort(sortDir === "desc" ? "asc" : "desc", name); // Call the onSort prop
  };
  useEffect(() => {
    if (!crrSort) {
      setSortName("id");
    } else {
      setSortName(crrSort);
    }
    if (!sortDrr) {
      setSortDir("desc");
    } else {
      setSortDir(sortDrr);
    }
  }, [crrSort, sortDrr]);
  return (
    // <div onClick={handleSort} className="cursor-pointer inline ml-1">
    //   {isSortedAsc ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
    // </div>
    <div onClick={(e) => handleSort(e)} className="cursor-pointer inline ml-1">
      {/* {isSortedAsc ? <ArrowUpOutlined /> : <ArrowDownOutlined />} */}
      {sortDir.toLowerCase() === "desc" ? (
        <ArrowDownOutlined />
      ) : (
        <ArrowUpOutlined />
      )}
    </div>
  );
};
export default SortingArrow;
