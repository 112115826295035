import Axios from "./axios";

export const addTemplateApi = (data) => {
  return Axios.post("admin/opportunity-template-create", data);
};
export const updateTemplateApi = (data, id) => {
  return Axios.post(`admin/opportunity-template-update/${id}`, data);
};
export const getTemplateApi = () => {
  return Axios.get("admin/opportunity-template-list");
};
export const getOpportunityListing = (filter) => {
  return Axios.get(
    `admin/opportunity-list?page=${filter?.page ?? ""}&page_size=${
      filter?.limit ?? ""
    }&sort_field=${filter?.sort_field ?? ""}&sort_direction=${
      filter?.sort_direction ?? ""
    }&status=${filter?.status ?? ""}&country=${filter?.country ?? ""}&search=${
      filter?.search ?? ""
    }&user_id=${filter?.user_id ?? ""}`
  );
};

export const updateOpStatusApi = (id, data) => {
  return Axios.put(`admin/opportunity-update-status/${id}`, data);
};
export const getOpDetail = (id) => {
  return Axios.get(`admin/opportunity-detail/${id}`);
};
export const deleteOppApi = (id) => {
  return Axios.delete(`admin/opportunity-delete/${id}`);
};
export const getPendingAffListApi = (id, filter) => {
  return Axios.get(
    `admin/opportunity-pending-request/${id}?status=${
      filter?.status ?? ""
    }&page_size=${filter?.pageSize ?? ""}&page=${filter?.current ?? ""}`
  );
};
export const getAssociateAff = (id, filter) => {
  return Axios.get(
    `admin/opportunity-associate-affiliate/${id}?search=${
      filter?.search ?? ""
    }&page_size=${filter?.pageSize ?? ""}&page=${filter?.current ?? ""}`
  );
};
export const createOpportunityApi = (data) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return Axios.post(`admin/opportunity-create`, data, config);
};
export const updateOpportunityApi = (id, data) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return Axios.put(`admin/opportunity-update/${id}`, data, config);
};
export const pendingAffActionApi = (id, data) => {
  return Axios.put(`admin/affiliate-opportunity-update-status/${id}`, data);
};

export const getStagesListApi = (id) => {
  return Axios.get(`admin/opportunity-stage-list/${id}`);
};
export const getOPAssDataApi = (id, type, filter) => {
  return Axios.get(
    `admin/opportunity-associate-customer/${id}?type=${type}&user_id=${JSON.stringify(
      filter?.user_id
    )}&page_size=${filter?.pageSize ?? ""}&page=${filter?.page ?? ""}&stage=${
      JSON.stringify(filter?.stage) ?? ""
    }`
  );
};

export const getOppCommisionApi = (id, filter) => {
  return Axios.get(
    `admin/opportunity-associate-commission/${id}/?page_size=10&page=${
      filter?.page ?? ""
    }&status=${filter?.status ?? ""}`
  );
};

export const updateMarkPaidApi = (id, data) => {
  return Axios.put(`admin/opportunity-commission-action/${id}`, data);
};
