import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";

const validationRule = {};
const validatePhoneNumber = (rule, value) => {
  if (!value) {
    return Promise.reject("Please enter your phone number");
  }

  try {
    const phoneNumber = parsePhoneNumber(value);
    if (phoneNumber && isValidPhoneNumber(value)) {
      return Promise.resolve();
    } else {
      return Promise.reject("Please enter a valid phone number");
    }
  } catch (error) {
    return Promise.reject("Please enter a valid phone number");
  }
};

validationRule.nameRule = (filedName) => {
  return [
    {
      required: true,
      message: `Please enter your ${filedName?.toLowerCase() ?? "field"} name `,
    },
    {
      max: 100,
      message: `${filedName ?? "Field"} name should not be exceed 100 char`,
    },
    {
      pattern: /^[A-Za-z\s]+$/,
      message: `${
        filedName ?? "Field"
      } name can only contain letters and spaces`,
    },
    {
      validator: (_, value) => {
        if (value && value.trim().length < 3) {
          return Promise.reject(
            new Error(
              `${
                filedName ?? "Field"
              } name should be minimum 3 characters long.`
            )
          );
        }
        return Promise.resolve();
      },
    },
  ];
};

validationRule.emailRule = () => {
  return [
    {
      required: true,
      message: "Please enter your Email address.",
    },
    {
      type: "email",
      message: "Please enter valid Email address.",
    },
    {
      max: 100,
      message: "Email should not exceed 100 char.",
    },
  ];
};
validationRule.phoneRule = (required) => {
  return [{ validator: validatePhoneNumber }];
};
export default validationRule;
