import { Modal, Row, Col } from "antd";
const PendingRequestDetails = ({
  isOpen,
  onOk,
  onCancel,
  ReasonText,
  data,
  loading
}) => {
  return (
    <>
      <Modal
        title={ReasonText}
        centered
        open={isOpen}
        onCancel={onCancel}
        onOk={onOk}
        width={600}
        okText="Approve"
        cancelText="Cancel"
        confirmLoading={loading}
      >
        <Row gutter={[20, 20]} className="pt-5">
          <Col span={6}>Affiliate name</Col>
          <Col span={18}>{data?.user?.first_name + " " + data?.user?.last_name}</Col>
          <Col span={6}>ID</Col>
          <Col span={18}>#{data?.user_id}</Col>
          <Col span={6}>Email address</Col>
          <Col span={18}>{data?.user?.email}</Col>
          <Col span={6}>Reject reason</Col>
          <Col span={18}>{data?.reject_reason ?data?.reject_reason:"-" }</Col>
         
          <Col span={6}>Status</Col>
          <Col span={18}>
            <span
              class={`py-2 px-3 bg-slate-100 ${
                data?.status === "pending" ? "text-[orange]" : "text-[red]"
              } text-xs rounded-lg `}
            >
              {data?.status}
            </span>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
export default PendingRequestDetails;
