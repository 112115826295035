// src/axiosInstance.js
import axios from "axios";
import storage from "../Utils/storageUtils";
import { TOKEN_NAME } from "../Utils/constant";

let baseURL = `${process.env.REACT_APP_BASE_PATH}api/v1/`;
// Create an instance of axios
const Axios = axios.create({
  baseURL: baseURL, // Replace with your API base URL
  timeout: 10000, // Optional: Specify a timeout in milliseconds
  headers: {
    "Content-Type": "application/json",
    "x-api-key": "king-credit-582ef09-4f-8b0b-31124e-593e5ca",
  },
});

// Use a function to attach interceptors
const attachInterceptors = (navigate, toast) => {
  Axios.interceptors.request.use(
    (config) => {
      let token = storage.getItem(TOKEN_NAME);
      // Perform actions before the request is sent, like adding auth tokens
      if (token) config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      // Handle request error
      return Promise.reject(error);
    }
  );

  Axios.interceptors.response.use(
    (response) => {
      // Perform actions on the response data
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        localStorage.clear();

        // Redirect to home page
        navigate("/"); // or use window.location.href = '/' if history is not available
      }
      // Handle other response errors
      return Promise.reject(error);
    }
  );
};
export default Axios;
export { attachInterceptors };
