import { useEffect, useState } from "react";
import { Route, Routes, useNavigate, Navigate } from "react-router-dom";
import { Layout } from "antd";
import HeaderComponent from "../Layout/HeaderComponent";
import LeftSidebar from "../Layout/LeftSidebar";
import Login from "../Pages/Auth/Login";
import ForgotPassword from "../Pages/Auth/ForgotPassword";
import ResetPassword from "../Pages/Auth/ResetPassword";
import ManageServiceProviders from "../Pages/ManageServiceProviders";
import CreateServiceProvider from "../Pages/ManageServiceProviders/CreateServiceProvider";
import ViewServiceProvider from "../Pages/ManageServiceProviders/ViewServiceProvider";
import AccountSettings from "../Pages/AccountSettings";
import ManageOpportunity from "../Pages/ManageOpportunity";
import CreateOpportunity from "../Pages/ManageOpportunity/CreateOpportunity";
import OpportunityDetail from "../Pages/ManageOpportunity/OpportunityDetail";
import EditOpportunity from "../Pages/ManageOpportunity/EditOpportunity";
import ManageAffiliates from "../Pages/ManageAffiliates";
import AffiliateDetail from "../Pages/ManageAffiliates/AffiliateDetail";
import EditAffiliate from "../Pages/ManageAffiliates/EditAffiliate";
import { attachInterceptors } from "../service/axios";
import { toast } from "react-toastify";
import Editor from "../Pages/ManageOpportunity/editor";
import storage from "../Utils/storageUtils";
import { TOKEN_NAME } from "../Utils/constant";
import PublicRoute from "./PublicRoute";
import StatusUpdate from "../Pages/statusUpadate/statusUpdate";
const { Content } = Layout;

const Routing = () => {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [isRoles, setIsRoles] = useState(false);

  const toggleSidebar = (collapsedState) => {
    setCollapsed(collapsedState);
  };
  useEffect(() => {
    let isToken = storage.getItem(TOKEN_NAME);
    if (isToken) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
    setIsRoles(true);
  }, [navigate]);
  useEffect(() => {
    attachInterceptors(navigate, toast);
  }, []);
  return (
    // <>
    //   <Routes>
    //     <Route
    //       path="/login"
    //       element={
    //         <PublicRoute>
    //           <Login />
    //         </PublicRoute>
    //       }
    //     />
    //   </Routes>
    // </>
    <>
      {isRoles ? (
        <Routes>
            <Route path="/test" element={<StatusUpdate />} />
          {/* auth Route */}
          {!isLogin ? (
            <Route
              path="/*"
              element={
                !isLogin ? (
                  <Routes>
                    <Route path="/" element={<Login />} />
                    <Route
                      path="/forgot-password"
                      element={<ForgotPassword />}
                    />
                    <Route
                      path="/reset-password/:token"
                      element={<ResetPassword />}
                    />
                    <Route path="*" element={<Navigate to={"/"}></Navigate>} />
                  </Routes>
                ) : (
                  <Navigate to={"/manage-affiliates"} />
                )
              }
            />
          ) : (
            <Route
              path="/*"
              element={
                isLogin ? (
                  <Layout style={{ minHeight: "100vh" }}>
                    <LeftSidebar
                      collapsed={collapsed}
                      toggleCollapsed={toggleSidebar}
                    />
                    <Layout>
                      <HeaderComponent toggleCollapsed={toggleSidebar} />
                      <Content style={{ margin: "16px 16px" }}>
                        <Routes>
                          {/* Dashboard Route */}

                          <Route
                            path="/manage-affiliates"
                            element={<ManageAffiliates />}
                          />
                          <Route
                            path="/view-affiliates/:id"
                            element={<AffiliateDetail />}
                          />
                          <Route
                            path="/edit-affiliates/:id"
                            element={<EditAffiliate />}
                          />
                          <Route
                            path="/manage-service-providers"
                            element={<ManageServiceProviders />}
                          />
                          <Route
                            path="/create-service-providers"
                            element={<CreateServiceProvider />}
                          />
                          <Route
                            path="/update-service-providers/:id"
                            element={<CreateServiceProvider />}
                          />
                          <Route
                            path="/view-service-providers/:id"
                            element={<ViewServiceProvider />}
                          />
                          <Route
                            path="/account-setting"
                            element={<AccountSettings />}
                          />
                          <Route
                            path="/manage-opportunity"
                            element={<ManageOpportunity />}
                          />
                          <Route
                            path="/create-opportunity"
                            element={<CreateOpportunity />}
                          />
                          <Route
                            path="/opportunity-details/:id"
                            element={<OpportunityDetail />}
                          />
                          <Route
                            path="/edit-opportunity/:id"
                            element={<CreateOpportunity/>}
                          />
                          <Route path="/editor" element={<Editor />} />
                          <Route
                            path="*"
                            element={
                              <Navigate to={"/manage-opportunity"}></Navigate>
                            }
                          />
                        </Routes>
                      </Content>
                    </Layout>
                  </Layout>
                ) : (
                  <Navigate to={"/"} />
                )
              }
            />
          )}
        </Routes>
      ) : (
        ""
      )}
    </>
  );
};
export default Routing;
