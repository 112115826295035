import React from "react";
import Routing from "./Route/route";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  return (
    <>
      <ToastContainer autoClose={2000}  hideProgressBar={true} pauseOnHover={false} />
      <Routing />
    </>
  );
};
export default App;
