import Axios from "./axios";

export const getCountriesApi = () => {
  return Axios.get("country");
};
export const getUpdateDataApi = (id) => {
  return Axios.get(`common/customer-list-by-opportunity/${id}`);
};
export const updateStatusApi = (id, data) => {
  return Axios.post(`common/customer-update-stage-commission/${id}`, data);
};
