import { Button, Drawer } from "antd";
import React, { useEffect, useRef } from "react";

const TemplateViewSideBar = ({ open, onClose, content,onEdit,handleUse }) => {
  const htmlContainerRef = useRef(null);

  useEffect(() => {
    if (htmlContainerRef.current) {
      // Disable all buttons
      const buttons = htmlContainerRef.current.querySelectorAll('button');
      buttons.forEach(button => {
        button.disabled = true;
      });

      // Disable all anchor tags by preventing default action
      const anchors = htmlContainerRef.current.querySelectorAll('a');
      anchors.forEach(anchor => {
        anchor.addEventListener('click', (event) => {
          event.preventDefault();
        });
        
      });
    }
  }, [content]);
  return (
    <>
      <Drawer title={content?.html_template} onClose={onClose} open={open}
      footer={
        <div className="flex justify-end w-full gap-5">
          <Button onClick={() => handleUse(content)}>Use this</Button>
          <Button onClick={onEdit}>Edit this</Button>
        </div>
      }
      >
        <div
          ref={htmlContainerRef}
          dangerouslySetInnerHTML={{ __html: content?.html_template_data }}
        />
      </Drawer>
    </>
  );
};

export default TemplateViewSideBar;
