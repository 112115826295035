import moment from "moment/moment";

export const getCountryName = (countryList, short_code) => {
  if (!countryList || !countryList.length || !short_code) return "N/A";
  let country_name = countryList.find((data) => data.short_name === short_code);
  if (country_name) {
    return country_name.name;
  }
  return short_code;
};

export const formatDate = (date) => {
  if (!date) return "-";
  return moment(date).format("MM/DD/YYYY");
};

export const spStatus = {
  pending: "Invitation pending",
  active: "Active",
  inactive: "Inactive",
};
export const generateUrl = (name, path, id) => {
  return `${path}/${id}/${name}`;
};

export const getDealDate = (currentStage, allStages) => {
  return formatDate(
    allStages?.find((data) => data?.slug === currentStage)?.date
  );
};
export const removeUnderScore = (str) => {
  if (!str) return "";
  return str
    .replace(/_/g, " ")
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) //
    .join(" ");
};
