import { Button, Input, Pagination, Select } from "antd";
import { FunnelPlotOutlined } from "@ant-design/icons";
import EmptyComponent from "../../../Component/Empty";
import { formatDate, getDealDate } from "../../../Utils/commonFun";
import { useNavigate } from "react-router-dom";
const { Search } = Input;
const filterByCommissionStatus = [
  {
    value: "pending",
    label: "Pending",
  },
  {
    value: "paid",
    label: "Paid",
  },
];
const Commission = ({ data, filter, setFilter, search, setSearch }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="flex justify-end gap-3  mb-4">
        <FunnelPlotOutlined />
        <Select
          placeholder="Filter by commission status"
          options={filterByCommissionStatus}
          onChange={(e) => setFilter({ ...filter, status: e, page: 1 })}
          value={filter?.status}
          style={{
            width: 220,
          }}
        />
        <Search
          placeholder="search by service provider, opportunity name"
          style={{
            width: 450,
          }}
          onChange={(e) => {
            let value = e.target.value;
            if (value?.length > 2 || value?.length < search?.length) {
              setFilter({ ...filter, search: value, page: 1 });
            }

            setSearch(value);
          }}
          value={search}
          className="mx-1.5 cursor-pointer"
        />
        <Button
          type="primary"
          onClick={() => {
            setFilter({ ...filter, search: "", status: null, page: 1 });
            setSearch("");
          }}
        >
          Clear filter
        </Button>
      </div>
      <div className="relative overflow-x-auto">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 bg-gray-100">
            <tr>
              <th scope="col" className="px-3 py-3 whitespace-nowrap">
                Opportunity Name
              </th>
              <th scope="col" className="px-3 py-3 whitespace-nowrap">
                Service Provider name
              </th>
              <th scope="col" className="px-3 py-3">
                Lead Generation Date
              </th>
              <th scope="col" className="px-3 py-3">
                Deal Closure Date
              </th>
              <th scope="col" className="px-3 py-3">
                Platform Commission percentage
              </th>
              <th scope="col" className="px-3 py-3">
                Platform Commission Amount
              </th>
              <th scope="col" className="px-3 py-3">
                Affiliate Commission percentage
              </th>
              <th scope="col" className="px-3 py-3">
                Affiliate Commission Amount
              </th>
              <th scope="col" className="px-3 py-3">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(data?.data) && data?.data?.length > 0 ? (
              data?.data?.map((com, index) => (
                <tr>
                  <td
                    key={index}
                    className="px-3 py-4 vertical-top cursor-pointer"
                    onClick={() =>
                      navigate("/opportunity-details/" + com?.opportunity?.id)
                    }
                  >
                    {com?.opportunity?.opportunity_name}
                  </td>
                  <td
                    key={index}
                    className="px-3 py-4 vertical-top cursor-pointer"
                    onClick={() =>
                      navigate(
                        "/view-service-providers/" + com?.opportunity?.user?.id
                      )
                    }
                  >
                    {com?.opportunity?.user?.first_name}
                  </td>
                  <td key={index} className="px-3 py-4 vertical-top">
                    {formatDate(com?.customer?.created_at)}
                  </td>
                  <td key={index} className="px-3 py-4 vertical-top">
                    {/* {formatDate(com?.opportunity?.created_at)} */}
                    {getDealDate(
                      com?.customer?.stage,
                      com?.customer?.stage_timeline
                    )}
                  </td>
                  <td key={index} className="px-3 py-4 vertical-top">
                    {com?.customer?.affiliate_commission_per
                      ? com?.customer?.affiliate_commission_per + "%"
                      : "-"}
                  </td>
                  <td key={index} className="px-3 py-4 vertical-top">
                    {com?.customer?.platform_commission_amount
                      ? "$" + com?.customer?.platform_commission_amount
                      : "-"}
                  </td>
                  <td key={index} className="px-3 py-4 vertical-top">
                    {com?.customer?.affiliate_commission_per
                      ? com?.customer?.affiliate_commission_per + "%"
                      : "-"}
                  </td>{" "}
                  <td key={index} className="px-3 py-4 vertical-top">
                    {com?.customer?.affiliate_commission_amount
                      ? "$" + com?.customer?.affiliate_commission_amount
                      : "-"}
                  </td>
                  <td key={index} className="px-3 py-4 vertical-top ">
                    <p className="capitalize py-2 px-3 bg-slate-100 text-black text-xs rounded-lg ">
                      {com?.status}
                    </p>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8} className="text-center">
                  <EmptyComponent />
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <Pagination
          total={data?.totalCount}
          current={filter?.page}
          onChange={(page) => {
            setFilter({ ...filter, page: page });
          }}
        />
      </div>
    </>
  );
};
export default Commission;
