import { Card, Divider, Typography, Row, Col, Tabs, Button } from "antd";
import { LeftOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TabPane from "antd/es/tabs/TabPane";
import Opportunity from "./AffiliateDetailTabs/Opportunity";
import Commission from "./AffiliateDetailTabs/Commission";
import { useEffect, useState } from "react";
import {
  deleteAffApi,
  getAffCommDataApi,
  getAffiliateLisDetail,
  getAffOppDataApi,
} from "../../service/affiliate.service";
import DeleteAccountRequest from "../../Modal/DeleteAccountRequest";
import { toast } from "react-toastify";
let comTimer = null;
let opTimer = null;
const AffiliateDetail = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [commissionData, setCommissionData] = useState([]);
  const [filterCommision, setFilterCommision] = useState({});
  const [searchCommision, setSearchCommision] = useState("");
  const [opData, setOpData] = useState({});
  const [opFilter, setOpFilter] = useState({});
  const [searchOp, setSearchOp] = useState("");
  const params = useParams();

  const getData = async () => {
    try {
      let res = await getAffiliateLisDetail(params?.id);
      setData(res?.data?.data);
    } catch (error) {
      console.log("Error while fetching data", error);
    }
  };
  const getCommisionData = async () => {
    try {
      let res = await getAffCommDataApi(params?.id, filterCommision);
      if (res?.status === 200) {
        setCommissionData(res?.data?.data);
      }
    } catch (error) {
      console.log("Error while fetching data", error);
    }
  };
  useEffect(() => {
    clearTimeout(comTimer);
    comTimer = setTimeout(() => {
      getCommisionData();
    }, 300);
  }, [filterCommision]);
  const handleDeleteOk = async () => {
    try {
      if (!params?.id) return;
      setDeleteLoading(true);
      let res = await deleteAffApi(params?.id);
      if (res?.status === 200) {
        toast.success(res?.data?.message);
        setIsDeleteModalOpen(false);
        navigate(-1);
      } else {
        toast.error(res?.data?.message);
      }
      setDeleteLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
      setDeleteLoading(false);
    }
  };
  const getOppData = async () => {
    try {
      let res = await getAffOppDataApi(params?.id,opFilter);
      if (res?.status === 200) {
        setOpData(res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    clearTimeout(opTimer);
    opTimer = setTimeout(() => {
      getOppData();
    }, 300);
    
  }, [opFilter]);
  const handleDeleteCancel = () => {
    if (deleteLoading) return;
    setIsDeleteModalOpen(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Card>
      <div className="flex items-center justify-between relative mb-4">
        <Typography className="text-xl font-semibold" align={"left"}>
          <LeftOutlined className="mr-2" onClick={() => navigate(-1)} />
          Affiliate Detail
        </Typography>
        <div className="flex justify-end flex-wrap gap-3 items-center	">
          <Button
            type="primary"
            onClick={() => navigate("/edit-affiliates/" + params?.id)}
            icon={<EditOutlined />}
          >
            Edit
          </Button>
          <Button
            type="primary"
            icon={<DeleteOutlined />}
            onClick={() => setIsDeleteModalOpen(true)}
          >
            Delete
          </Button>
        </div>
      </div>
      <Divider />
      <div>
        <Row>
          <Col span={10}>
            <div className="mr-4 my-5">
              <div className="font-semibold text-[17px]">Affiliate ID</div>
              <div className="text-sm">#{data?.affiliateData?.id}</div>
            </div>
          </Col>
          <Col span={10}>
            <div className="mr-4 my-5">
              <div className="font-semibold text-[17px]">Name </div>
              <div className="text-sm">
                {data?.affiliateData?.first_name}{" "}
                {data?.affiliateData?.last_name}
              </div>
            </div>
          </Col>
          <Col span={10}>
            <div className="mr-4 my-5">
              <div className="font-semibold text-[17px]">Email address</div>
              <div className="text-sm">{data?.affiliateData?.email}</div>
            </div>
          </Col>
          <Col span={10}>
            <div className="mr-4 my-5">
              <div className="font-semibold text-[17px]">Phone number</div>
              <div className="text-sm">{data?.affiliateData?.phone}</div>
            </div>
          </Col>
          <Col span={10}>
            <div className="mr-4 my-5">
              <div className="font-semibold text-[17px]">Country</div>
              <div className="text-sm">{data?.affiliateData?.country}</div>
            </div>
          </Col>
          <Col span={10}>
            <div className="mr-4 my-5">
              <div className="font-semibold text-[17px]">
                User name or subdomain URL
              </div>
              <div className="text-sm">{data?.affiliateData?.username}</div>
            </div>
          </Col>
          <Col span={10}>
            <div className="mr-4 my-5">
              <div className="font-semibold text-[17px]">Status</div>
              <div className="text-sm ">{data?.affiliateData?.status}</div>
            </div>
          </Col>
        </Row>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Opportunity" key="1">
            <Opportunity
              data={opData}
              filter={opFilter}
              setFilter={setOpFilter}
              search={searchOp}
              setSearch={setSearchOp}
            />
          </TabPane>
          <TabPane tab="Commission" key="2">
            <Commission
              data={commissionData}
              filter={filterCommision}
              setFilter={setFilterCommision}
              search={searchCommision}
              setSearch={setSearchCommision}
            />
          </TabPane>
        </Tabs>
      </div>
      {/* delete modal */}
      <DeleteAccountRequest
        ConfirmationHeading="Delete Account Request"
        ConfirmationParagraph="If you accept the delete account request for this affiliate user, their account will be deleted."
        isOpen={isDeleteModalOpen}
        onOk={handleDeleteOk}
        loading={deleteLoading}
        onCancel={handleDeleteCancel}
      />
    </Card>
  );
};
export default AffiliateDetail;
