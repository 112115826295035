import { useEffect, useState } from "react";
import { Avatar, Layout, Menu } from "antd";
import {
  DownOutlined,
  UserOutlined,
  UnlockOutlined,
  LoginOutlined,
  BellOutlined,
} from "@ant-design/icons";
import ChangePassword from "../../Modal/ChangePassword";
import ConfirmationModal from "../../Modal/ConfirmationModal";
import storage from "../../Utils/storageUtils";
import { TOKEN_NAME, USER_DATA } from "../../Utils/constant";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { getProfileApi } from "../../service/auth.service";
const { Header } = Layout;
const HeaderComponent = () => {
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const [profileData, setProfileData] = useState({
    name: "",
    profile_image: "",
  });
  const location = useLocation();
  const Navigate = useNavigate();
  const showChangePasswordModal = () => {
    setIsChangePasswordModalOpen(true);
  };
  const selectedKey = location.pathname;
  const showLogoutModal = () => {
    setIsLogoutModalOpen(true);
  };
  const handleLogoutOk = () => {
    storage.removeItem(TOKEN_NAME);
    storage.removeItem(USER_DATA);
    Navigate("/");
    setIsLogoutModalOpen(false);
  };
  const handleLogoutCancel = () => {
    setIsLogoutModalOpen(false);
  };
  const displayData = async () => {
    try {
      
      let profileData = await getProfileApi();
      setProfileData({
        name: profileData?.data?.data?.first_name,
        profile_image: profileData?.data?.data.profile_image,
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    displayData();
  }, [location]);

  return (
    <>
      <Header style={{ background: "#fff", padding: "0px 16px" }}>
        <div className="text-right">
          <Menu
            mode="horizontal"
            className="ml-auto flex justify-end items-center"
            selectedKeys={[selectedKey]}
          >
            <div className="mr-1  items-center flex">
              <BellOutlined style={{ fontSize: "20px" }} />
            </div>
            <Menu.SubMenu
              key="SubMenu"
              title={
                <>
                  <span
                    className="cursor-pointer inline-block"
                    onClick={(e) => e.preventDefault()}
                  >
                    <Avatar
                      size={40}
                      icon={
                        profileData?.profile_image ? (
                          <img src={profileData?.profile_image} />
                        ) : (
                          <UserOutlined />
                        )
                      }
                    />
                    <span className="mx-2">{profileData?.name}</span>
                    <DownOutlined />
                  </span>
                </>
              }
            >
              <Menu.Item key="/account-setting">
                <Link to="/account-setting">
                  <UserOutlined className="mr-1" /> Profile
                </Link>
              </Menu.Item>
              <Menu.Item
                onClick={showChangePasswordModal}
                key="change-password"
              >
                <UnlockOutlined className="mr-1" /> Change Password
              </Menu.Item>
              <Menu.Item onClick={showLogoutModal}>
                <LoginOutlined className="mr-1" /> Logout
              </Menu.Item>
            </Menu.SubMenu>
          </Menu>
        </div>
      </Header>
      <ChangePassword
        isOpen={isChangePasswordModalOpen}
        setIsOpen={setIsChangePasswordModalOpen}
      />
      <ConfirmationModal
        ConfirmationHeading="Logout"
        ConfirmationParagraph="Are you sure you want to logout?"
        isOpen={isLogoutModalOpen}
        onOk={handleLogoutOk}
        onCancel={handleLogoutCancel}
      />
    </>
  );
};
export default HeaderComponent;
