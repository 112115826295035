import Axios from "./axios";

export const getAffiliateListApi = (filter) => {
  return Axios.get(
    `admin/affiliates-list?page=${filter?.page ?? ""}&page_size=${
      filter?.limit ?? ""
    }&sort_field=${filter?.sort_field ?? ""}&sort_direction=${
      filter?.sort_direction ?? ""
    }&status=${filter?.status ?? ""}&country=${filter?.country ?? ""}&search=${
      filter?.search ?? ""
    }`
  );
};

export const getAffiliateLisDetail = (id) => {
  return Axios.get(`admin/affiliate-detail/${id}`);
};
export const updateStatusAffApi = (id, data) => {
  return Axios.put(`admin/affiliate-update-status/${id}`, data);
};
export const deleteAffApi = (id) => {
  return Axios.delete(`admin/affiliate-delete/${id}`);
};
export const updateAffilateApi = (id, data) => {
  return Axios.put(`admin/affiliate-edit/${id}`, data);
};
export const getAffCommDataApi = (id, filter) => {
  return Axios.get(
    `admin/affiliate-associate-commission-list/${id}?page=${
      filter?.page ?? ""
    }&search=${filter?.search ?? ""}&status=${filter?.status ?? ""}`
  );
};

export const getAffOppDataApi = (id, filter) => {
  return Axios.get(
    `admin/affiliate-associate-opportunity-list/${id}?page=${
      filter?.page ?? ""
    }&search=${filter?.search ?? ""}&status=${filter?.status ?? ""}`
  );
};
