import CryptoJS from "crypto-js";

const storage = {};

const seceretKey = process.env.REACT_APP_CRYPTO_KEY;

const encryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), seceretKey).toString();
};

// Decrypt data
const decryptData = (cipherText) => {
  const bytes = CryptoJS.AES.decrypt(cipherText, seceretKey);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

// Get item from LocalStorage with decryption
storage.getItem = (key) => {
  const encryptedValue = localStorage.getItem(key);
  if (encryptedValue) {
    return decryptData(encryptedValue);
  }
  return null;
};
// Set item in LocalStorage with encryption
storage.setItem = (key, value) => {
  const encryptedValue = encryptData(value);
  localStorage.setItem(key, encryptedValue);
};
// Remove item from LocalStorage
storage.removeItem = (key) => {
  localStorage.removeItem(key);
};
export default storage;
