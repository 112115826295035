import {
  Card,
  Divider,
  Typography,
  Row,
  Col,
  Tooltip,
  Button,
  Switch,
} from "antd";
import {
  LeftOutlined,
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  deleteServiceProviderApi,
  resetPwdLinkAPi,
  serviceProviderDetailApi,
  spOpportunityListApi,
  updateSpStatusApi,
} from "../../service/sp.service";
import { toast } from "react-toastify";
import { formatDate, generateUrl } from "../../Utils/commonFun";
import { ACTIVE, PENDING } from "../../Utils/constant";
import DeleteAccountRequest from "../../Modal/DeleteAccountRequest";

const ViewServiceProvider = () => {
  const navigate = useNavigate();
  let params = useParams();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [resetPwdLoading, setResetPwdLoading] = useState(false);
  const [associateData, setAssociateData] = useState([]);
  const getData = async () => {
    try {
      let res = await serviceProviderDetailApi(params?.id);

      if (res?.status === 200) {
        setData(res?.data?.data);
        setLoading(false);
      } else {
        toast.error(res?.data?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    }
  };
  const handleStatus = async (checked) => {
    try {
      setData({ ...data, status: checked ? "active" : "inactive" });
      let res = await updateSpStatusApi(params?.id, {
        status: checked ? "active" : "inactive",
      });
      if (res?.status === 200) {
        toast.success(res?.data?.message);
      } else {
        toast.error(res?.data?.message);
        setData({ ...data, status: checked ? "inactive" : "active" });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setData({ ...data, status: checked ? "inactive" : "active" });
    }
  };
  const handleDeleteOk = async () => {
    try {
      setDeleteLoading(true);
      let res = await deleteServiceProviderApi(params?.id);
      if (res?.status === 200) {
        toast.success(res?.data?.message);
        navigate(-1);
        setIsDeleteModalOpen(false);
      } else {
        toast.error(res?.data?.message);
      }
      setDeleteLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
      setDeleteLoading(false);
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
  };
  const resetPasswordLinkHandle = async (id) => {
    try {
      setResetPwdLoading(true);
      let res = await resetPwdLinkAPi(params?.id);
      if (res?.status === 200) {
        toast.success(res?.data?.message);
      } else {
        toast.error(res?.data?.message);
      }
      setResetPwdLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);

      setResetPwdLoading(false);
    }
  };
  const getSpAssData = async () => {
    try {
      let res = await spOpportunityListApi(params?.id);
      if (res?.status === 200) {
        setAssociateData(res?.data?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getSpAssData();
    getData();
  }, []);

  return (
    <Card className="cardHeightFull">
      <DeleteAccountRequest
        ConfirmationHeading="Delete"
        ConfirmationParagraph="Are you sure you want to delete this Service provider ?"
        isOpen={isDeleteModalOpen}
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
        loading={deleteLoading}
        btnTxt="Delete"
      />
      <div className="flex items-center justify-between relative mb-4">
        <Typography className="text-xl font-semibold" align={"left"}>
          <LeftOutlined className="mr-2" onClick={() => navigate(-1)} />
          View Service Provider
        </Typography>
        {!loading && (
          <div className="flex justify-end flex-wrap gap-3 items-center	">
            {data?.status?.toLowerCase() === PENDING ? (
              <Button
                loading={resetPwdLoading}
                onClick={resetPasswordLinkHandle}
              >
                Resend Password link
              </Button>
            ) : (
              <Switch
                checkedChildren="Active"
                unCheckedChildren="Inactive"
                onChange={handleStatus}
                checked={data?.status?.toLowerCase() === ACTIVE}
              />
            )}
            <Button
              type="primary"
              onClick={() =>
                navigate(`/update-service-providers/${params?.id}`)
              }
              icon={<EditOutlined />}
            >
              Edit
            </Button>

            <Button
              type="primary"
              onClick={() => setIsDeleteModalOpen(true)}
              icon={<DeleteOutlined />}
            >
              Delete
            </Button>
          </div>
        )}
      </div>
      <Divider />
      <div>
        <Row>
          <Col span={10}>
            <div className="mr-4 my-5">
              <div className="font-semibold text-[17px]">
                Service provider ID
              </div>
              <div className="text-sm">#{data?.id}</div>
            </div>
          </Col>
          <Col span={10}>
            <div className="mr-4 my-5">
              <div className="font-semibold text-[17px]">
                Mode data fetching
              </div>
              <div className="text-sm">{data?.mode_data_fetching}</div>
            </div>
          </Col>
          <Col span={10}>
            <div className="mr-4 my-5">
              <div className="font-semibold text-[17px]">Service provider</div>
              <div className="text-sm">{data?.sp_name}</div>
            </div>
          </Col>
          <Col span={10}>
            <div className="mr-4 my-5">
              <div className="font-semibold text-[17px]">
                Contact person name
              </div>
              <div className="text-sm">{data?.first_name}</div>
            </div>
          </Col>
          <Col span={10}>
            <div className="mr-4 my-5">
              <div className="font-semibold text-[17px]">
                Contact person email address
              </div>
              <div className="text-sm">{data?.email}</div>
            </div>
          </Col>
          <Col span={10}>
            <div className="mr-4 my-5">
              <div className="font-semibold text-[17px]">
                Contact person phone number
              </div>
              <div className="text-sm">{data?.phone}</div>
            </div>
          </Col>
          <Col span={10}>
            <div className="mr-4 my-5">
              <div className="font-semibold text-[17px]">Created date</div>
              <div className="text-sm">{formatDate(data?.created_at)}</div>
            </div>
          </Col>
          <Col span={10}>
            <div className="mr-4 my-5">
              <div className="font-semibold text-[17px]">Industry</div>
              <div className="text-sm">{data?.industry}</div>
            </div>
          </Col>
          <Col span={10}>
            <div className="mr-4 my-5">
              <div className="font-semibold text-[17px]">
                User name or subdomain
              </div>
              <div className="text-sm">{data?.username}</div>
            </div>
          </Col>
          <Col span={10}>
            <div className="mr-4 my-5">
              <div className="font-semibold text-[17px]">Country</div>
              <div className="text-sm">{data?.country}</div>
            </div>
          </Col>
          <Col span={10}>
            <div className="mr-4 my-5">
              <div className="font-semibold text-[17px]">Logo or image</div>
              <div>
                <img src={data?.logo} alt="Logo" className="w-[60px] mt-1" />
              </div>
            </div>
          </Col>
          <Col span={10}>
            <div className="mr-4 my-5">
              <div className="font-semibold text-[17px]">
                Supporting documents
              </div>
              <div className="text-sm cursor-pointer">
                {Array.isArray(data?.supporting_documents) &&
                  data?.supporting_documents?.map((name) => (
                    <>
                      <Link
                        to={generateUrl(name, data?.document_path, data?.id)}
                        target="blank"
                        className="link"
                      >
                        {name}
                      </Link>
                      <br></br>
                    </>
                  ))}
              </div>
            </div>
          </Col>
          <Col span={10}>
            <div className="mr-4 my-5">
              <div className="font-semibold text-[17px]">
                Business credentials
              </div>
              <div className="text-sm cursor-pointer">
                {data?.business_credentials}
              </div>
            </div>
          </Col>

          <Col span={10}>
            <div className="mr-4 my-5">
              <div className="font-semibold text-[17px]">
                Service or product details
              </div>
              <div className="text-sm">
                {data?.item_detail ? data?.item_detail : "-"}
              </div>
            </div>
          </Col>
        </Row>
        {Array.isArray(associateData) && associateData?.length ?<>
          <div className="font-semibold text-xl">Associated Opportunities</div>
          <table className="w-[85%] mt-3 text-sm text-left text-gray-500">
            <thead className="text-xs text-gray-700 bg-gray-100">
              <tr>
                <th scope="col" className="px-3 py-3 whitespace-nowrap">
                  ID
                </th>
                <th scope="col" className="px-3 py-3">
                  Opportunity name
                </th>
                <th scope="col" className="px-3 py-3">
                  Created date
                </th>
                <th
                  scope="col"
                  className="px-3 py-3 text-center whitespace-nowrap"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(associateData) &&
                associateData?.map((ass, index) => (
                  <tr key={index} className="bg-white border-b">
                    <th
                      scope="row"
                      className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap vertical-top cursor-pointer"
                    >
                      #{ass?.id}
                    </th>
                    <td className="px-3 py-4 vertical-top">
                      {ass?.opportunity_name}
                    </td>
                    <td className="px-3 py-4 vertical-top">
                      {formatDate(ass?.created_at)}
                    </td>
                    <td className="px-3 py-4 text-center whitespace-nowrap vertical-top">
                      <Tooltip
                        title="View"
                        onClick={() =>
                          navigate(`/opportunity-details/${ass?.id}`)
                        }
                      >
                        <EyeOutlined className="mx-1.5 cursor-pointer" />
                      </Tooltip>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </>:""}
      </div>
    </Card>
  );
};
export default ViewServiceProvider;
