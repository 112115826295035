import React, { useEffect, useState } from "react";
import { Table, Select, Button ,Tooltip} from "antd";
import {
  FilterOutlined,
  ExportOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { getAffiliateListApi } from "../../service/affiliate.service";
import { formatDate, removeUnderScore } from "../../Utils/commonFun";
import { useNavigate } from "react-router-dom";
let timer = null;
const BussinessDataTable = ({
  columns,
  data,
  stages,
  setFilter,
  filter,
  type,
}) => {
  const [affiliateList, setAffiliateList] = useState([]);
  const [searchAff, setSearchAff] = useState("");
  const [searchAffVal, setSearchAffVal] = useState("");
  const [allColumns, setAllColumns] = useState([]);
  const [allData, setAllData] = useState([]);
  const [stageList, setStageList] = useState([]);
  const [condCol, setCondCol] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    let dataC = columns.map((item) => {
      return {
        title: removeUnderScore(item),
        dataIndex: item,
        key: item,
      };
    });
    let dataD = data?.data?.map((item) => {
      let { customer_detail_json, ...rest } = item;
      return {
        ...customer_detail_json,
        ...rest,
      };
    });
    setAllData(dataD);
    setAllColumns(dataC);
  }, [columns, data]);

  // const leadColumns = [
  //   {
  //     title: "Affiliate",
  //     dataIndex: "leadAffiliate",
  //     key: "name",
  //   },
  //   {
  //     title: "Affiliate status",
  //     dataIndex: "AffiliateStatus",
  //     key: "status",
  //   },
  //   {
  //     title: "Business name",
  //     dataIndex: "businessName",
  //     key: "businessName",
  //   },
  //   {
  //     title: "Contact",
  //     dataIndex: "contact",
  //     key: "contact",
  //   },
  //   {
  //     title: "Source",
  //     dataIndex: "source",
  //     key: "source",
  //   },
  //   {
  //     title: "Position",
  //     dataIndex: "position",
  //     key: "position",
  //   },
  //   {
  //     title: "Employees",
  //     dataIndex: "employees",
  //     key: "employees",
  //   },
  //   {
  //     title: "Industry",
  //     dataIndex: "industry",
  //     key: "industry",
  //   },
  //   {
  //     title: "Status stage",
  //     dataIndex: "statusStage",
  //     key: "statusStage",
  //   },
  //   {
  //     title: "Status timeline",
  //     dataIndex: "statusTimeline",
  //     key: "statusTimeline",
  //   },
  //   {
  //     title: "Last Updated date time",
  //     dataIndex: "lastDate",
  //     key: "lastDate ",
  //   },
  //   {
  //     title: "Potential Commission",
  //     dataIndex: "potentialCommission",
  //     key: "potentialCommission",
  //   },
  //   {
  //     title: "Action",
  //     dataIndex: "action",
  //     key: "action ",
  //   },
  // ];
  const getAffData = async () => {
    try {
      let res = await getAffiliateListApi({
        search: searchAff,
        limit: 1000,
      });

      if (res?.status === 200) {
        let data = res?.data?.data?.data;

        let modifyData = data?.map((item) => {
          return {
            label: item?.first_name + " " + item?.last_name,
            value: item?.id,
          };
        });
        setAffiliateList(modifyData);
      }
    } catch (error) {}
  };

  useEffect(() => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      getAffData();
    }, 300);
  }, [searchAff]);
  useEffect(() => {
    let sList = [];
    stages?.map((item) => {
      sList.push({
        label: item?.key + " " + item?.value,
        value: item?.slug,
      });
    });
    setStageList(sList);
  }, [stages]);
  useEffect(() => {
    if (type === "deal") {
      setCondCol([
        {
          title: "Total deal amount",
          key: "total_deal_amount",
          dataIndex: "total_deal_amount",
        },
        {
          title: "Platform commission (%)",
          dataIndex: "platform_commission_per",
          key: "platform_commission_per",
        },
        {
          title: "Platform commission ($)",
          dataIndex: "platform_commission_amount",
          key: "platform_commission_amount",
        },
        {
          title: "Affiliate commission (%)",
          dataIndex: "affiliate_commission_per",
          key: "affiliate_commission_per",
        },
        type === "deals"
          ? {
              title: "Affiliate commission ($)",
              dataIndex: "affiliate_commission_amount",
              key: "affiliate_commission_amount",
            }
          : {},
      ]);
    } else if (type === "lead") {
      setCondCol([
        {
          title: "Potential Commission",
          dataIndex: "potential_commission_per",
          key: "potential_commission_per",
        },
      ]);
    }
  }, [type]);

  return (
    <div>
      <div className="flex justify-end mb-4 gap-x-2.5	">
        <FilterOutlined className="" />
        <Select
          maxTagCount="responsive"
          showSearch
          placeholder="Filter by affiliate name "
          options={affiliateList}
          style={{
            width: "100%",
          }}
          notFoundContent="No data found"
          mode="multiple"
          maxTagPlaceholder={(omittedValues) => (
            <Tooltip
              overlayStyle={{
                pointerEvents: 'none',
              }}
              title={omittedValues.map(({ label }) => label).join(', ')}
            >
              <span>More...</span>
            </Tooltip>
          )}
          filterOption={false}
          value={filter.user_id}
          onChange={(e) => {
            if (!e.length) {
              setSearchAffVal("");
              setSearchAff("");
            }
            setFilter((prev) => {
              return {
                ...prev,
                user_id: e,
                page: 1,
              };
            });
          }}
          onSearch={(e) => {
            if (e.length > 2 || e.length < searchAffVal.length) {
              setSearchAff(e);
            }
            setSearchAffVal(e);
          }}
        />
        <Select
          placeholder="Filter by status"
          maxTagCount="responsive"
          options={stageList}
          style={{
           
            maxWidth: "100%",
            width: "100%",
          }}
          showSearch={false}
          mode="multiple"
          maxTagPlaceholder={(omittedValues) => (
            <Tooltip
              overlayStyle={{
                pointerEvents: 'none',
              }}
              title={omittedValues.map(({ label }) => label).join(', ')}
            >
              <span>More...</span>
            </Tooltip>
          )}

          value={filter.stage}
          onChange={(e) => {
            setFilter((prev) => {
              return {
                ...prev,
                stage: e,
                page: 1,
              };
            });
          }}
        />
        <Button
          type="primary"
          onClick={() => setFilter({ page: 1, user_id: [], stage: [] })}
        >
          Clear filter
        </Button>
        {/* <Button type="primary" icon={<ExportOutlined />}>
          Export
        </Button> */}
        <Button type="primary" icon={<DownloadOutlined />}>
          Download
        </Button>
      </div>
      <div className="w-full overflow-x-auto overflow-y-hidden">
        <Table
          className="table-auto  min-w-[1140px]"
          columns={[
            {
              title: "Affiliate",
              dataIndex: "user",
              key: "first_name",
              render: (user) => (
                <p onClick={() => navigate("/view-affiliates/" + user?.id)}>
                  {user?.first_name + " " + user?.last_name}
                </p>
              ),
            },
            {
              title: "Affiliate status",
              dataIndex: "user",
              key: "status",
              render: (user) => user?.status,
            },

            {
              title: "Source",
              dataIndex: "source",
              key: "source",
            },
            ...allColumns,
            {
              title: "Status and timeline",
              dataIndex: "stage",
              key: "stage",
              render: (_, data) => {
                let stg = data?.stage_timeline?.find(
                  (val) => val?.stage === data?.stage
                );
                return stg ? stg?.key + " " + stg?.value : "-";
              },
            },
            {
              title: "Last Updated date time",
              // dataIndex: "stage_timeline",
              key: "statusStage",
              render: (_, data) => {
                return formatDate(
                  data.stage_timeline.find((val) => val.stage === data.stage)
                    ?.date
                );
              },
            },

            ...condCol,
          ]}
          dataSource={[...allData]}
          pagination={{
            current: filter?.page,
            pageSize: 10,
            total: data?.totalCount,
            onChange: (page) => {
              setFilter({ ...filter, page: page });
            },
          }}
        />
      </div>
      <div className="text-base font-semibold">
        Total - {type} {data?.totalCount ? data?.totalCount : 0}
      </div>
    </div>
  );
};

export default BussinessDataTable;
