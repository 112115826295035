import { useState } from "react";
import { Layout, Menu } from "antd";
import { MdManageHistory, MdOutlineManageAccounts } from "react-icons/md";
import { TbAffiliate } from "react-icons/tb";
import { RiUserSettingsLine } from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";
const { Sider } = Layout;

const urlMappings = {
  opportunity: [
    "/manage-opportunity",
    "/create-opportunity",
    "/opportunity-details",
    "/opportunity-details/:id",
    "/edit-opportunity/:id"
  ],
  manage_affiliates: [
    "/manage-affiliates",
    "/view-affiliates/:id",
    "/edit-affiliates/:id",
  ],
  manage_service_providers: [
    "/manage-service-providers",
    "/view-service-providers/:id",
    "/update-service-providers/:id",
    "/create-service-providers",
  ],
  account_setting: ["/account-setting"],
};

function getParentUrl(childUrl) {
  for (const [parentUrl, childUrls] of Object.entries(urlMappings)) {
    for (const pattern of childUrls) {
      // Convert the pattern (e.g., '/view-affiliates/:id') to a regex
      const regexPattern = new RegExp(`^${pattern.replace(/:\w+/g, "\\w+")}$`);
      if (regexPattern.test(childUrl)) {
        return parentUrl;
      }
    }
  }
  return "/"; // Default to '/' if no mapping found
}
const LeftSidebar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  const location = useLocation();
  const selectedKey = location.pathname;

  const parentUrl = getParentUrl(selectedKey);
  return (
    <Sider
      width={240}
      className="customLeftSidebar"
      collapsible
      collapsed={collapsed}
      onCollapse={toggleCollapsed}
    >
      <div className="logo flex justify-center h-[56px] my-4 transition-opacity duration-500">
        {collapsed ? (
          <span style={{ color: "rgba(255, 255, 255, 0.65)" }}>Logo</span>
        ) : (
          <span
            style={{ color: "rgba(255, 255, 255, 0.65)", fontSize: "32px" }}
          >
            Logo
          </span>
        )}
      </div>
      <Menu theme="dark" selectedKeys={[parentUrl]} mode="inline">
        <Menu.Item key="opportunity" icon={<MdManageHistory size={18} />}>
          <Link to="/manage-opportunity">Manage Opportunity</Link>
        </Menu.Item>
        <Menu.Item key="manage_affiliates" icon={<TbAffiliate size={18} />}>
          <Link to="/manage-affiliates">Manage Affiliates</Link>
        </Menu.Item>
        <Menu.Item
          key="manage_service_providers"
          icon={<MdOutlineManageAccounts size={18} />}
        >
          <Link to="/manage-service-providers">Manage Service Providers</Link>
        </Menu.Item>
        <Menu.Item
          key="account_setting"
          icon={<RiUserSettingsLine size={18} />}
        >
          <Link to="/account-setting">Account Settings</Link>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};
export default LeftSidebar;
