import Axios from "./axios";

export const loginApi = async (data) => {
  return Axios.post("auth/admin/login", data);
};

export const getProfileApi = async () => {
  return Axios.get("admin/profile");
};
export const changePasswordApi = async (data) => {
  return Axios.put("auth/change-password/", data);
};
export const updateProfileApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return Axios.put("admin/edit-profile", data, config);
};
export const deleteProfileImageApi = async () => {
  return Axios.delete(`admin/delete-profile-image`);
};

export const forgotPasswordAPi = async (data) => {
  return Axios.post(`admin/forget-password`, data);
};
export const resetPasswordApi = (data) => {
  return Axios.put(`admin/reset-password`, data);
};
