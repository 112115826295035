import { useState } from "react";
import { Modal } from "antd";
import TextArea from "antd/es/input/TextArea";
const RejectReasonModal = ({
  isOpen,
  onOk,
  onCancel,
  ReasonText,
  ReasonPlaceholder,
  validationError,
  rejectReason,
  setRejectReason,
  loading
}) => {
  return (
    <>
      <Modal
        title={ReasonText}
        centered
        open={isOpen}
        onCancel={onCancel}
        onOk={onOk}
        width={450}
        confirmLoading={loading}
        okText="Submit"
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <TextArea
          value={rejectReason}
          onChange={(e) => setRejectReason(e.target.value)}
          placeholder={ReasonPlaceholder}
          rows={3}
          style={{ marginTop: "0.5rem", marginBottom: "10px" }}
          maxLength={200}
          showCount
        />
        <p>{validationError}</p>
      </Modal>
    </>
  );
};
export default RejectReasonModal;