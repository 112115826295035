import { Modal } from "antd";
const DeleteAccountRequest = ({
  isOpen,
  onOk,
  onCancel,
  loading,
  ConfirmationHeading,
  ConfirmationParagraph,
  btnTxt,
  btnTxtNo,
}) => {
  return (
    <>
      <Modal
        title={
          ConfirmationHeading ? ConfirmationHeading : "Delete Account Request"
        }
        centered
        open={isOpen}
        confirmLoading={loading}
        onCancel={onCancel}
        onOk={onOk}
        width={500}
        okText={btnTxt ?? "Accept"}
        cancelText={btnTxtNo ?? "Cancel"}
      >
        <p className="text-sm mb-4">
          {ConfirmationParagraph
            ? ConfirmationParagraph
            : ` If you accept the delete account request for this affiliate user,
          their account will be deleted.`}
        </p>
      </Modal>
    </>
  );
};
export default DeleteAccountRequest;
